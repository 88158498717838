@import '../../../libs/shared/mi-ui-global-styles/src/styles/base/variables/colors';
.fixed-header .m-header {
  position: relative;
}
.resorts-search {
  .cq-Editable-dom {
    .search-form-position {
      position: relative;
      margin: 0 auto;
      left: 0;
      top: 0;
    }
  }
  .aem-GridColumn {
    .search-form-position {
      position: unset;
      width: 100%;
    }
  }
}

.destinations-search {
  .cq-Editable-dom {
    .search-form-position {
      position: relative;
      margin: 0 auto;
      left: 0;
      top: 0;
    }
  }
  .aem-GridColumn {
    .search-form-position {
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
  .primary-container {
    box-shadow: none !important;
  }
  .search-form-position {
    position: absolute;
    width: 100%;
    top: 0 !important;
  }
}

.sticky-search-top {
  top: 0 !important;
}
.search-form-wrapper {
  .m-dropdown-header {
    color: $base-10;
  }
}

.datepicker-calendar-wrapper-mobile .DayPicker-Caption div,
.datepicker-calendar-wrapper-multi-tabs .DayPicker-Caption div {
  color: $base-10;
}
.date-picker-wrapper .shop-datepicker-modal .datepicker-calendar-wrapper-mobile,
.date-picker-wrapper .shop-datepicker-modal .datepicker-calendar-wrapper-multi-tabs {
  .datepicker-calendar-wrapper-mobile .DayPicker-Caption div {
    color: $base-10;
  }
}
.date-picker-wrapper .shop-datepicker-modal .datepicker-calendar-wrapper-mobile {
  margin-top: 5.125rem !important;
}
.m-stepper-wrapper,
.mobile-input-field .trailing-element {
  color: $base-10;
}
.card-horizontal-feature-wrapper {
  overflow: hidden;
  @media (min-width: 768px) and (max-width: 1199px) {
    overflow: initial;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 767px) {
    overflow: initial;
  }
}
.cq-Editable-dom {
  .glide__slides {
    width: 100% !important;
  }
}

.dynamic-prop-card-carousel {
  width: 100%;
}
