/* stylelint-disable color-no-hex */

/// @group Colors
/// Standard Palette
/// Base
$base-10: #1c1c1c !default;
$base-15: 28, 28, 28;
$base-20: #ffffff !default;
$base-25: 255, 255, 255;

/// Accent
$accent-10: #ff8d6b !default;

/// Neutral
$neutral-00: #000000 !default;
$neutral-10: #f4f4f4 !default;
$neutral-20: #eeeeee !default;
$neutral-30: #c4c4c4 !default;
$neutral-40: #707070 !default;

// Bonvoy
$bvy-base-10: #231c19 !default;
$bvy-base-20: #f9f6f4 !default;
$bvy-base-30: #ff8d6b !default;

// Bonvoy Accent
$bvy-accent-10: #ffb995 !default;
$bvy-accent-20: #dc6f50 !default;
$bvy-accent-30: #ffdaa0 !default;
$bvy-accent-40: #cad7eb !default;

//// Merch
$merch-10: #ff9662 !default;
$merch-20: #477d00 !default;
$merch-30: #1c1c1c !default;
$merch-40: #fbc02d !default;
$merch-50: #ffffff !default;
$merch-60: #ffb995 !default;
$merch-70: #f0fbe3 !default;

/// Loyalty Palette solid
$member-solid: #ff9662 !default;
$member-solidAlt: #f8f8f8 !default;
$silver-solid: #707488 !default;
$gold-solid: #8c6f49 !default;
$platinum-solid: #5e5f61 !default;
$titanium-solid: #554749 !default;
$ambassador-solid: #231c19 !default;

// Alert (New)
$alert-10: #ffffff !default;
$alert-20: #c4c4c4 !default;
$alert-30: #477d00 !default;
$alert-40: #b94600 !default;
$alert-50: #d0021b !default;


//========== Below variables are to deprecated in GS version 4.0.0 ===============
// $accent-20: #b84c16 !default;

// $neutral-50: #303030 !default;

// $alert-00: #ffffff !default;
// $alert-05: #fcebed !default;
// $alert-10: #d0021b !default;
// $alert-20: #ffffff !default;
// $alert-30: #d0021b !default;
// $alert-40: #b94600 !default;
// $alert-50: #477d00 !default;
// $alert-60: #1c1c1c !default;

// $opaque-01: 0.1;
// $opaque-02: 0.54;
// $opaque-03: 0.60;
// $opaque-09: 0.90;
